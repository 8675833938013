<template>
<div class="app-navbar">
	<div class="d-flex justify-content-between align-items-center interior rounded">
		<div class="d-flex align-items-center title-wrapper">
			<div class="d-flex align-items-center">
				<router-link to="/library">
					<img class="logo" v-lazy="logo" style="height: 1.75rem;" alt="Amino Logo" />
				</router-link>
			</div>
			<div class="d-none d-md-flex align-items-center storage-container border-left" v-if="!isPro">
				<UsedStorage>
					<router-link to="/pro">
						Upgrade
					</router-link>
				</UsedStorage>
			</div>
		</div>
		<div class="d-flex align-items-center">
			<router-link to="/pro" class="d-flex align-items-center cursor-pointer border-right" v-if="activeCoupon.badge && !isPro">
				<div class="item position-relative">
					<div class="d-flex align-items-center">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 24px;" class="mr-2">
							<path fill="#7F78D2" fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
						</svg>
						<div class="font-weight-bold small">
							{{ activeCoupon.badge }}
						</div>
					</div>
				</div>
			</router-link>
			<router-link to="/account/plans" class="d-flex align-items-center cursor-pointer border-right" v-else-if="isPastDue">
				<div class="item position-relative">
					<div class="d-flex align-items-center">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" style="width: 24px;" class="mr-2">
							<path stroke="#7F78D2" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
						</svg>
						<div class="font-weight-bold small">
							Payment Failed
						</div>
					</div>
				</div>
			</router-link>
			<div class="d-none d-md-flex align-items-center cursor-pointer border-right">
				<div class="item position-relative">
					<ToolsMenu></ToolsMenu>
				</div>
			</div>
			<div>
				<UserMenu></UserMenu>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
const UserMenu = () => import('@/components/UserMenu');
const UsedStorage = () => import('@/components/amino-vue-components/UsedStorage');
const ToolsMenu = () => import('@/components/ToolsMenu');
const logo = require('../assets/images/icon.svg');
export default {
	name: 'NavbarWorkspace',
	mixins: [ makeFindMixin({ service: 'coupons' }) ],
	components: {
		UserMenu,
		UsedStorage,
		ToolsMenu
	},
	data() {
		return {
			unreadNotifications: 0,
			logo
		};
	},
	computed: {
		activeCoupon () {
			return this.coupons[0] || {};
		},
		couponsParams () {
			return { query: {} };
		},
		routeName() {
			return this.$route.name;
		},
		primaryRouteActive() {
			if (['library', 'collections'].includes(this.routeName)) {
				return true;
			}

			return false;
		},
		Notifications() {
			const {
				Notifications
			} = this.$FeathersVuex.api;
			return Notifications;
		},
		NotificationAggregate() {
			const {
				NotificationAggregate
			} = this.$FeathersVuex.api;
			return NotificationAggregate;
		}
	},
	methods: {
		resetUnreadNotifications() {
			this.unreadNotifications = 0;
		},
		countUnreadNotifications() {
			this.NotificationAggregate.find().then(notifications => {
				for (let notification of notifications) {
					if (!notification.readBy.length) {
						this.unreadNotifications++;
					}
				}
			});
		},
		incrementUnreadNotifications() {
			this.unreadNotifications++;
		},
		setUnreadNotifications() {
			this.resetUnreadNotifications();
			this.countUnreadNotifications();
		}
	},
	async mounted() {
		this.countUnreadNotifications();
		this.Notifications.on('created', this.incrementUnreadNotifications);
		this.Notifications.on('updated', this.setUnreadNotifications);
		this.Notifications.on('patched', this.setUnreadNotifications);
	},
	beforeDestroy() {
		this.Notifications.off('created', this.incrementUnreadNotifications);
		this.Notifications.off('updated', this.setUnreadNotifications);
		this.Notifications.off('patched', this.setUnreadNotifications);
	}
}
</script>

<style lang="scss" scoped>
.app-navbar {
	z-index: 999;
	position: fixed;
	top: 0;
	width: 100%;
	right: 0;
	padding: 1.5rem;
	.interior {
		padding: 1rem;
		width: 100%;
		box-shadow: 0 4px 40px rgba(12,16,22,1);
		background-color: var(--color-700);
	}
	@media (max-width: 900px) {
		position: relative;
	}
}

.border-left {
	height: 2.25rem;
	margin-left: 1.5rem;
	padding-left: 1.5rem;
	border-color: var(--color-500) !important;
}

.border-right {
	height: 2.25rem;
	margin-right: 1.5rem;
	padding-right: 1.5rem;
	border-color: var(--color-500) !important;
}

.storage-container {
	height: 36px;
	border-color: var(--color-500) !important;
	margin-right: 1.5rem;
	padding-right: 1.5rem;
}

nav {
	> .item {
		cursor: pointer;
		font-weight: var(--font-weight-bold);
		&:not(:last-of-type) {
			margin-right: 1.5rem;
		}
	}
}
</style>
